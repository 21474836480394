import { playMedia } from "../../../../../assets/js/utils/playPauseMedia";

declare var Waypoint: any;
declare var VIDEO_MD_WIDTH: number;

function initGrid(grid: HTMLElement) {
  const video = grid.querySelector('video');
  if (!(video instanceof HTMLVideoElement)) return; // return if there is no video

  const videoControls = grid.querySelector('.grid__video-control-overlay');
  const playButton = grid.querySelector('.grid__video-control-overlay__play');
  const pauseButton = grid.querySelector('.grid__video-control-overlay__pause');

  // helpers
  function playInView(el: HTMLVideoElement) {
    const wpUp = new window.Waypoint({
      element: el,
      offset: '75%',
      handler: (direction: string) => {
        if (direction === 'down') {
          playMedia(el);
        }
      },
    });
    const wpDown = new window.Waypoint({
      element: el,
      offset: '-75%',
      handler: (direction: string) => {
        if (direction === 'up') {
          playMedia(el);
        }
      },
    });
  }

  function bindAccessibilityPause(video: HTMLVideoElement) {
    video.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        e.preventDefault();
        if (video.paused) {
          playMedia(video);
        } else {
          video.pause();
        }
      }
    });
  }

  function setupPlayPauseEvents(
    video: HTMLVideoElement,
    playButton: HTMLSpanElement,
    pauseButton: HTMLSpanElement,
  ) {
    video.addEventListener('play', () => {
      playButton.style.display = 'none';
      pauseButton.style.display = 'flex';
    });
    video.addEventListener('pause', () => {
      playButton.style.display = 'flex';
      pauseButton.style.display = 'none';
    });
  }

  function setupPlayPauseButtons(
    video: HTMLVideoElement,
    videoControls: HTMLDivElement,
  ) {
    if (video.videoWidth > VIDEO_MD_WIDTH) {
      videoControls.classList.add('grid__video-control-overlay__position-lg');
    } else {
      videoControls.classList.add('grid__video-control-overlay__position-sm');
    }

    videoControls.addEventListener('click', () => {
      if (video.paused) {
        playMedia(video);
      } else {
        video.pause();
      }
    });
  }

  // check if video and replay controls are in DOM, if not return.
  if (
    !(video instanceof HTMLVideoElement) ||
    !(videoControls instanceof HTMLDivElement) ||
    !(playButton instanceof HTMLSpanElement) ||
    !(pauseButton instanceof HTMLSpanElement)
  ) {
    return;
  }

  playInView(video);
  bindAccessibilityPause(video);
  setupPlayPauseButtons(video, videoControls);
  setupPlayPauseEvents(video, playButton, pauseButton);
}

const grids = Array.from(document.querySelectorAll('.js-grid'));
grids.forEach((grid) => {
  if (grid instanceof HTMLElement) {
    initGrid(grid);
  }
});
