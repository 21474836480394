export const playMedia = async (media: HTMLMediaElement) => {
  try {
    await media.play();
  } catch (error) {
    console.error(error);
    throw new Error(`Error playing media: ${error}`);
  }
};

export const togglePlayPause = (media: HTMLMediaElement) => {
  const isPlaying = media.currentTime > 0 && !media.paused && !media.ended
    && media.readyState > media.HAVE_CURRENT_DATA;

  return isPlaying ? media.pause() : playMedia(media);
};
